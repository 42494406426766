// jacket
import React, { useEffect, useRef, Suspense, useState, useMemo } from 'react'
import { Canvas } from '@react-three/fiber'
import { PerspectiveCamera, useGLTF, Html, useProgress, Environment } from '@react-three/drei'
import { debounce } from 'lodash'
import { Scrollbars } from 'react-custom-scrollbars-2';
import './index.css'
import axios from 'axios';

import drykornLogo from './assets/drykorn/drykorn-logo.svg'

import { IconUser } from './icons/IconUser.jsx';
import { IconEdit } from './icons/IconEdit.jsx';
import { IconBag } from './icons/IconBag.jsx';
import { IconSearch } from './icons/IconSearch.jsx';
import { IconCaret } from './icons/IconCaret.jsx';
import { IconDoubleCaret } from './icons/IconDoubleCaret.jsx';
import { IconFitting } from './icons/IconFitting.jsx';
import { IconAddBag } from './icons/IconAddBag.jsx';
import { IconCheck } from './icons/IconCheck.jsx';

import { CameraControls } from './CameraControl';

const avatarOptions = [
  {
    // file: 'https://dev.fitting-room.hosting.jfnet.de/images/body-type/human_10000.glb',
    file: require('./assets/drykorn/avatar/Men_Henry_V5_001.glb'),
    img: require('./assets/drykorn/avatar/Men_Henry_V5_001.png'),
  },
  {
    file: require('./assets/drykorn/avatar/Men_Leo_V5_001.glb'),
    img: require('./assets/drykorn/avatar/Men_Leo_V5_001.png'),
  },
  {
    file: require('./assets/drykorn/avatar/Men_Nate_V1_001.glb'),
    img: require('./assets/drykorn/avatar/Men_Nate_V1_001.png'),
  },
  {
    file: require('./assets/drykorn/avatar/Men_Thomas_V1_001.glb'),
    img: require('./assets/drykorn/avatar/Men_Thomas_V1_001.png'),
  },
];
const topOptions = [
  {
    file: require('./assets/drykorn/shirt/dustin_rose_001.glb'),
    category: 'Shirt',
    name: 'Dustin Rose',
    img: require('./assets/drykorn/shirt/dustin_rose_001.png'),
    desc: 'Cotton T-shirt',
    id: 'dustin_rose_001',
  },
  {
    file: require('./assets/drykorn/shirt/levin_001.glb'),
    category: 'Shirt',
    name: 'Levin',
    img: require('./assets/drykorn/shirt/levin_001.png'),
    desc: 'Cotton Short-sleeve',
    id: 'levin_001',
  },
  {
    file: require('./assets/drykorn/shirt/kili_001.glb'),
    category: 'Shirt',
    name: 'Kili',
    img: require('./assets/drykorn/shirt/kili_001.png'),
    desc: 'Cotton T-shirt',
    id: 'kili_001',
  },
  {
    file: require('./assets/drykorn/shirt/hunt_ba_001.glb'),
    category: 'Shirt',
    name: 'Hunt Ba',
    img: require('./assets/drykorn/shirt/hunt_ba_001.png'),
    desc: 'Cotton T-shirt',
    id: 'hunt_ba_001',
  },
  {
    file: require('./assets/drykorn/shirt/leander_001.glb'),
    category: 'Shirt',
    name: 'Leander',
    img: require('./assets/drykorn/shirt/leander_001.png'),
    desc: 'Sport Shirt',
    id: 'leander_001',
  },
  {
    file: require('./assets/drykorn/shirt/santos_rose_001.glb'),
    category: 'Shirt',
    name: 'Santos Rose',
    img: require('./assets/drykorn/shirt/santos_rose_001.png'),
    desc: 'Polo Shirt',
    id: 'santos_rose_001',
  },
];
const bottomOptions = [
  {
    file: require('./assets/drykorn/trousers/chasy_001.glb'),
    category: 'Pant',
    name: 'Chasy',
    img: require('./assets/drykorn/trousers/chasy_001.png'),
    desc: 'Relaxed Pant',
  },
  {
    file: require('./assets/drykorn/trousers/jeger_001.glb'),
    category: 'Pant',
    name: 'Jeger',
    img: require('./assets/drykorn/trousers/jeger_001.png'),
    desc: 'Street Pant',
  },
  {
    file: require('./assets/drykorn/trousers/teba_001.glb'),
    category: 'Pant',
    name: 'Teba',
    img: require('./assets/drykorn/trousers/teba_001.png'),
    desc: 'Relaxed Street Pant',
  },
  {
    file: require('./assets/drykorn/trousers/puy_rose_001.glb'),
    category: 'Short',
    name: 'Puy Rose',
    img: require('./assets/drykorn/trousers/puy_rose_001.png'),
    desc: 'Everyday look Short',
  },
];

const jacketOptions = [
  {
    isJacket: true,
    key: 'amelio',
    category: 'Sport',
    name: 'Amelio',
    img: require('./assets/drykorn/jacket/amelio.png'),
    desc: 'Relaxed Jacket',
    'dustin_rose_001': {
      key: 'amelio',
      file: require('./assets/drykorn/jacket/amelio_dustin_rose_001.glb'),
    },
    'levin_001': {
      key: 'amelio',
      file: require('./assets/drykorn/jacket/amelio_levin_001.glb'),
    },
    'kili_001': {
      key: 'amelio',
      file: require('./assets/drykorn/jacket/amelio_kili_001.glb'),
    },
    'hunt_ba_001': {
      key: 'amelio',
      file: require('./assets/drykorn/jacket/amelio_hunt_ba_001.glb'),
    },
    'leander_001': {
      key: 'amelio',
      file: require('./assets/drykorn/jacket/amelio_leander_001.glb'),
    },
    'santos_rose_001': {
      key: 'amelio',
      file: require('./assets/drykorn/jacket/amelio_santos_rose_001.glb'),
    },
  },
  {
    isJacket: true,
    key: 'edgo',
    category: 'Sport',
    name: 'Edgo',
    img: require('./assets/drykorn/jacket/edgo.png'),
    desc: 'Street Jacket',
    'dustin_rose_001': {
      key: 'edgo',
      file: require('./assets/drykorn/jacket/edgo_dustin_rose_001.glb'),
    },
    'levin_001': {
      key: 'edgo',
      file: require('./assets/drykorn/jacket/edgo_levin_001.glb'),
    },
    'kili_001': {
      key: 'edgo',
      file: require('./assets/drykorn/jacket/edgo_kili_001.glb'),
    },
    'hunt_ba_001': {
      key: 'edgo',
      file: require('./assets/drykorn/jacket/edgo_hunt_ba_001.glb'),
    },
    'leander_001': {
      key: 'edgo',
      file: require('./assets/drykorn/jacket/edgo_leander_001.glb'),
    },
    'santos_rose_001': {
      key: 'edgo',
      file: require('./assets/drykorn/jacket/edgo_santos_rose_001.glb'),
    },
  },
  {
    isJacket: true,
    key: 'jacket_s',
    category: 'Leather',
    name: 'Jacket S',
    img: require('./assets/drykorn/jacket/jacket_s.png'),
    desc: 'Authentic Leather Jacket',
    'dustin_rose_001': {
      key: 'jacket_s',
      file: require('./assets/drykorn/jacket/jacket_s_dustin_rose_001.glb'),
    },
    'levin_001': {
      key: 'jacket_s',
      file: require('./assets/drykorn/jacket/jacket_s_levin_001.glb'),
    },
    'kili_001': {
      key: 'jacket_s',
      file: require('./assets/drykorn/jacket/jacket_s_kili_001.glb'),
    },
    'hunt_ba_001': {
      key: 'jacket_s',
      file: require('./assets/drykorn/jacket/jacket_s_hunt_ba_001.glb'),
    },
    'leander_001': {
      key: 'jacket_s',
      file: require('./assets/drykorn/jacket/jacket_s_leander_001.glb'),
    },
    'santos_rose_001': {
      key: 'jacket_s',
      file: require('./assets/drykorn/jacket/jacket_s_santos_rose_001.glb'),
    },
  },
];

function getGroupedByCategoryItems(items) {
  return [
    ...items
      .reduce((reducer, obj) => {
        const key = obj.category;
        const item = reducer.get(key) ?
          {
            ...reducer.get(key),
            children: [
              ...reducer.get(key).children,
              obj,
            ],
          } :
          {
            category: key,
            children: [obj],
          };
        return reducer.set(key, item);
      }, new Map()).values(),
  ]
}

const allItemsLength = topOptions.length + bottomOptions.length + jacketOptions.length;

function Avatar({ constant, modelFile }) {
  const { scene: avatarScene, nodes: avatarNodes } = useGLTF(modelFile)
  useEffect(() => {
    avatarScene.scale.setScalar(1.5)
    avatarScene.traverse(function(obj) {
      if (obj.isMesh) {
        obj.material.roughness = 0.8
        obj.castShadow = true
      }
    })

  }, [avatarScene])

  return (
    <primitive object={avatarScene} />
  )
}

function Bottom({ constant, modelFile }) {
  const { scene: trouScene, nodes: trouNodes } = useGLTF(modelFile)

  useEffect(() => {
    trouScene.scale.setScalar(1.5)
    trouScene.traverse(function(obj) {
      if (obj.isMesh) {
        obj.material.roughness = 0.8
        obj.castShadow = true
      }
    })
  }, [trouScene])

  return (
    <primitive object={trouScene} />
  )
}

function Top({ constant, modelFile }) {
  const { scene: shirtScene, nodes: shirtNodes } = useGLTF(modelFile)

  useEffect(() => {
    shirtScene.scale.setScalar(1.5)
    shirtScene.traverse(function(obj) {
      if (obj.isMesh) {
        obj.material.roughness = 0.8
        obj.castShadow = true
      }
    })
  }, [shirtScene])


  return (
    <primitive object={shirtScene} />
  )
}

function Jacket({ constant, modelFile }) {
  const { scene: jacketScene, nodes: jacketNodes } = useGLTF(modelFile)

  useEffect(() => {
    jacketScene.scale.setScalar(1.5)
    jacketScene.traverse(function(obj) {
      if (obj.isMesh) {
        obj.material.roughness = 0.8
        obj.castShadow = true
      }
    })
  }, [jacketScene])


  return (
    <primitive object={jacketScene} />
  )
}

function Full({ constant, modelFile }) {
  const { scene: fullScene, nodes: fullNodes } = useGLTF(modelFile)

  useEffect(() => {
    fullScene.scale.setScalar(1.5)
    fullScene.traverse(function(obj) {
      if (obj.isMesh) {
        obj.material.roughness = 0.8
        obj.castShadow = true
      }
    })
  }, [fullScene])


  return (
    <primitive object={fullScene} />
  )
}

function StudioSoLights() {
  return (
    <>
      <Environment
        background={false}
        files={require('./assets/hdri/studio_s_02_1k.hdr')}
      />
      <ambientLight intensity={0.1} />
      <spotLight
        position={[4, 6, 6]}
        angle={0.9}
        intensity={0.3}
        castShadow={true}
      />
      <spotLight
        position={[0, 6, -6]}
        angle={0.9}
        intensity={0.1}
        castShadow={false}
      />
    </>
  )
}

function GroundAndFog() {
  return (
    <>
      <mesh rotation-x={Math.PI * -0.5} position={[0, -1.30, 0]} receiveShadow={true}>
        <planeGeometry args={[70, 70]} />
        <meshStandardMaterial color={'#ffffff'} metalness={0} roughness={0.89} />
      </mesh>
      <color attach="background" args={['#ffffff']} />
      <fog attach="fog" args={['#ffffff', 6, 14]} />
    </>
  );
}

function ClothList({ items, selected, onSelect, isSelectJacket, removeJacket }) {
  const groupedByCategoryItems = getGroupedByCategoryItems(items)
  const [categoryOpenStatus, setCategoryOpenStatus] = useState({});

  return <>
    {
     groupedByCategoryItems.slice().map((item, index) =>
      <div key={'categoryContainer' + item.category + index} className="mb-1">
        <button
          className="w-full py-1 pr-2 text-[12px] font-bold text-black flex items-center justify-between"
          onClick={() => {
            setCategoryOpenStatus({
              ...categoryOpenStatus,
              [item.category]: categoryOpenStatus[item.category] === undefined ? false : !categoryOpenStatus[item.category]
            })
          }}
        >
          <span>{ item.category } ({ item.children.length })</span> <div className={categoryOpenStatus[item.category] ? "" : "transform rotate-180"}><IconCaret /></div>
        </button>
        <ul
          key={'categoryGroup' + item.category + index}
          className={
            "grid grid-cols-1 lg:grid-cols-2 gap-[30px] mb-4"
            + (categoryOpenStatus[item.category] === undefined || categoryOpenStatus[item.category] === true ? ' block' : ' hidden')
          }
        >
          {
            item.children.slice().map((ch, chIndex) =>
              <li
                key={'children' + index + chIndex}
                className="grid grid-cols-2 gap-4 h-[148px]"
              >
                <div
                  className={
                    "relative w-full h-[148px] bg-[#F3F4F6] cursor-pointer"
                    + (selected && selected.img === ch.img ? ' pointer-events-none' : '')
                  }
                  onClick={() => onSelect(ch)}
                >
                  <img
                    src={ch.img}
                    alt={'children' + index + chIndex}
                    className={
                      "absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 transition-all duration-300 ease-in-out w-auto h-[135px] object-contain"
                      + ( isSelectJacket
                        ? (selected.key === ch.key ? ' opacity-40' : ' opacity-100 hover:scale-110')
                        : (selected && selected.img === ch.img ? ' opacity-40' : ' opacity-100 hover:scale-110')
                      )
                    }
                  />
                  {
                    isSelectJacket
                      ? selected.key === ch.key
                        ? <div className="absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 text-white">
                          Wearing
                        </div>
                        : <></>
                      : selected && selected.img === ch.img
                        ? <div className="absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 text-white">
                          Wearing
                        </div>
                        : <></>
                  }
                </div>
                <div className="flex flex-col justify-between h-[148px]">
                  <div>
                    <p>
                      { ch.name }
                    </p>
                    <p className="text-[12px] text-theme-black-2">
                      { ch.desc }
                    </p>
                    <p>
                      8.00 €
                    </p>
                  </div>
                  <div className="flex items-center">
                    {
                      isSelectJacket
                        ? selected.key === ch.key
                          ? <button
                            className={
                              "flex items-center justify-center min-w-[72px] h-[30px] py-4 bg-theme-black text-white rounded text-[12px]"
                              + " hover:bg-black/50"
                            }
                            onClick={removeJacket}
                          >
                            Remove
                          </button>
                          : <button
                            className={
                              "flex items-center justify-center min-w-[72px] h-[30px] py-4 bg-theme-black text-white rounded text-[12px]"
                              + " hover:bg-black/50"
                            }
                            onClick={() => onSelect(ch)}
                          >
                            <span>Try on</span>
                          </button>
                        : <button
                          className={
                            "flex items-center justify-center min-w-[72px] h-[30px] py-4 bg-theme-black text-white rounded text-[12px]"
                            + " hover:bg-black/50"
                            + (selected && selected.img === ch.img ? ' pointer-events-none' : '')
                          }
                          onClick={() => onSelect(ch)}
                        >
                          {
                            selected && selected.img === ch.img
                              ? <IconCheck />
                              : <span>Try on</span>
                          }
                        </button>
                    }
                    <div className="ml-4">
                      <IconAddBag />
                    </div>
                  </div>
                </div>
              </li>
            )
          }
        </ul>
      </div>
      )
    }
  </>
}

function ClothTabOption({ tab, selectedTop, selectedBottom, selectedJacket, onSelectTop, onSelectBottom, onSelectJacket, isSelectJacket, removeJacket }) {
  if (tab === 'top') {
    return <ClothList items={topOptions} selected={selectedTop} onSelect={onSelectTop} />
  }
  if (tab === 'bottom') {
    return <ClothList items={bottomOptions} selected={selectedBottom} onSelect={onSelectBottom} />
  }
  if (tab === 'jacket') {
    return <ClothList items={jacketOptions} selected={selectedJacket} onSelect={onSelectJacket} isSelectJacket={isSelectJacket} removeJacket={removeJacket} />
  }
  return <ClothList items={topOptions} selected={selectedTop} onSelect={onSelectTop} />
}

export default function App() {
  const cameraControlsRef = useRef(null);
  const camRef = useRef(null);
  const canvasRef = useRef(null);
  const [windowSize, setWindowSize] = useState(getWindowSize());
  const [isResetting, setIsResetting] = useState(false);
  const [selectAvatar, setSelectAvater] = useState(avatarOptions[0]);
  const [selectTop, setSelectTop] = useState(topOptions[0]);
  const [selectBottom, setSelectBottom] = useState(bottomOptions[0]);
  const [selectJacket, setSelectJacket] = useState(jacketOptions[0][topOptions[0].id]);
  const [isSelectJacket, setIsSelectJacket] = useState(false);
  const [isOpenMobile, setIsMobileOpen] = useState(false);
  const [isOpenSelectAvatar, setIsOpenSelectAvatar] = useState(false);
  const [scaling, setScaling] = useState(false);
  const [selectedTab, setSelectedTab] = useState('top');

  const [modelResults, setModelResults] = useState([]);

  const isMobileAndTabletControlVertical = useMemo(() => windowSize.innerWidth < 800, [windowSize.innerWidth]);

  useEffect(() => {
    function handleWindowResize() {
      setIsMobileOpen(false)
      setWindowSize(getWindowSize());
      const vh = window.innerHeight;
      // Then we set the value in the --vh custom property to the root of the document
      document.documentElement.style.setProperty('--vh', `${vh}px`);
    }
    handleWindowResize();
    window.addEventListener('resize', handleWindowResize);
    return () => {
      window.removeEventListener('resize', handleWindowResize);
    };
  }, []);

  useEffect(() => {
    let active = true;
    load();
    return () => { active = false };
    async function load() {
      // setModelResults([]) // this is optional
      const res = await axios.get('https://dev.fitting-room.hosting.jfnet.de/api/humans?name=Henry')
      if (!active) { return }
      setModelResults([res.data.image])
    }
  }, [])

  useEffect(() => {
    if (modelResults.length) {
      console.log('modelResults', modelResults)
      axios.get(modelResults)
    }
  }, [modelResults])

  function getWindowSize() {
    const { innerWidth, innerHeight } = window;
    return { innerWidth, innerHeight };
  }

  function onSelectAvatar(e) {
    setIsOpenSelectAvatar(false)
    setSelectAvater(e);
  }
  function onSelectTop(e) {
    setIsMobileOpen(false)
    if (isSelectJacket) {
      setSelectTop(e)
      const getSelectedJacketItem = jacketOptions.find((item) => item.key === selectJacket.key);
      const getJacketOfSelectedShirt = getSelectedJacketItem[e.id]
      setSelectJacket(getJacketOfSelectedShirt);
    } else {
      setSelectTop(e)
    }
  }
  function onSelectBottom(e) {
    setIsMobileOpen(false)
    setSelectBottom(e)
  }

  function onSelectJacket(e) {
    const getJacketOfSelectedShirt = e[selectTop.id]
    setIsMobileOpen(false)
    setIsSelectJacket(true);
    setSelectJacket(getJacketOfSelectedShirt);
  }
  function onCanvasCreated(state) {
    state.gl.localClippingEnabled = true;
    if (canvasRef.current) {
      canvasRef.current.addEventListener('wheel', checkScrollDirection);
      canvasRef.current.addEventListener('touchstart ', checkMobilePinchStart);
      canvasRef.current.addEventListener('touchmove  ', checkMobilePinchMove);
      canvasRef.current.addEventListener('touchend  ', checkMobilePinchEnd);
    }
  }
  function removeJacket() {
    setIsSelectJacket(false);
    setSelectJacket(null);
  }

  const checkMobilePinchStart = function(event) {
    // pinchStart
    if (event.touches.length === 2) {
      setScaling(true)
    }
  }

  const checkMobilePinchEnd = function(event) {
    // pinchEnd
    if (scaling) {
      setScaling(false)
    }
  }

  const checkMobilePinchMove = debounce(function(event) {
    // pinchMove
    if (scaling) {
      if (Math.abs(cameraControlsRef.current.distance) > 2.5 && Math.abs(cameraControlsRef.current.distance) < 5 && !isResetting) {
        cameraControlsRef.current.dolly(-4, true)
        setIsResetting(true)
        setTimeout(() => {
          cameraControlsRef.current.setTarget(0, 0, 0, true)
          setIsResetting(false)
        }, 1000)
      } else {
        cameraControlsRef.current.setTarget(0, 0, 0, true)
      }
    }
  }, 400)

  const checkScrollDirection = debounce(function(event) {
    if (!checkScrollDirectionIsUp(event)) {
      if (Math.abs(cameraControlsRef.current.distance) > 2.5 && Math.abs(cameraControlsRef.current.distance) < 5 && !isResetting) {
        cameraControlsRef.current.dolly(-4, true)
        setIsResetting(true)
        setTimeout(() => {
          cameraControlsRef.current.setTarget(0, 0, 0, true)
          setIsResetting(false)
        }, 1000)
      } else {
        cameraControlsRef.current.setTarget(0, 0, 0, true)
      }
    }
  }, 400);

  function checkScrollDirectionIsUp(event) {
    if (event.wheelDelta) {
      return event.wheelDelta > 0;
    }
    return event.deltaY < 0;
  }

  function Loader() {
    const { progress } = useProgress()
    return <Html center>
      <p className="font-bold text-center">
        Loading
      </p>
      <p className="font-bold text-center mt-2 whitespace-nowrap">
        {parseInt(progress)} %
      </p>
    </Html>
  }

  return (
    <>
      <div ref={canvasRef} className="relative w-full h-full" style={{height: 'var(--vh)'}}>
        <nav className="pl-[16px] pr-[16px] w-full h-[58px] flex items-center justify-between border-b border-theme-border">
          <div>
            <img src={drykornLogo} className="w-auto h-[40px] object-contain" alt="DRYKORN Logo" />
          </div>
          <div className="flex items-center">
            <div className="pr-6 mr-6 border-r border-theme-border">
              <IconSearch />
            </div>
            <div className="flex items-center gap-6">
              <div className="flex items-center">
                <IconFitting />
                <p className="text-[14px] ml-2 leading-none">{ allItemsLength }</p>
              </div>
              <div className="flex items-center">
                <IconBag />
                <p className="text-[14px] ml-2 leading-none text-theme-gray">7</p>
              </div>
              <IconUser />
            </div>
          </div>
        </nav>
        <div
          className="relative grid grid-cols-1 md:grid-cols-2 w-full"
          style={{height: 'calc(var(--vh) - 60px)'}}
        >
          <div className="absolute left-3 bottom-3 z-40 flex flex-col">
            <button
              className="relative bg-gray-200 border border-slate-400 w-14 h-16 group hover:border-black"
              onClick={() => setIsOpenSelectAvatar(!isOpenSelectAvatar) }
            >
              <img
                src={selectAvatar.img}
                alt="avatar"
                className={
                  "w-14 h-16 object-contain group-hover:opacity-50"
                  + (isOpenSelectAvatar ? ' opacity-50' : ' opacity-100')
                }
              />
              <IconEdit
                className={
                  "absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2"
                  + " group-hover:opacity-100"
                  + (isOpenSelectAvatar ? ' opacity-100' : ' opacity-0')
                }
              />
            </button>
            {
              isOpenSelectAvatar
                ? <ul className="absolute bottom-full flex flex-col">
                  {
                    avatarOptions.slice()
                      .filter((item) => item.file !== selectAvatar.file)
                      .map((item, index) =>
                        <li key={'avatar'+index}>
                          <button
                            className="bg-gray-200 border border-slate-400 w-[100px] h-[100px] hover:border-black"
                            onClick={() => onSelectAvatar(item)}
                          >
                            <img src={item.img} alt="avatar" className="w-full h-full object-contain" />
                          </button>
                        </li>
                      )
                  }
                </ul>
                : <></>
            }
          </div>
          {
            isOpenSelectAvatar
              ? <div className='fixed top-0 left-0 w-full z-30' style={{height: 'var(--vh)'}} onClick={() => setIsOpenSelectAvatar(false)}></div>
              : <></>
          }
          <Canvas
            className="w-full h-full cursor-move"
            onCreated={onCanvasCreated}
            shadows
          >
            <PerspectiveCamera
              ref={camRef}
              fov={30}
              near={0.1}
              far={100}
              makeDefault
              position={[0, 0.786969454578296, 6.955634977391649]}
            />
            <Suspense fallback={<Loader />}>
              <group position={[0, -1.3, 0]}>
                <Avatar modelFile={selectAvatar.file} />
                <Bottom modelFile={selectBottom.file} />
                {
                  isSelectJacket
                    ? <Jacket modelFile={selectJacket.file} />
                    : <Top modelFile={selectTop.file} />
                }
              </group>
            </Suspense>
            <CameraControls
              ref={cameraControlsRef}
              camRef={camRef}
            />
            <GroundAndFog />
            <StudioSoLights />
            {/* <Perf position="top-left" /> */}
          </Canvas>
          <div
            className={
              "absolute w-[320px] top-0 right-0 md:right-auto md:w-full md:relative border-l border-theme-boder bg-white h-full transition-all duration-200 ease-in-out md:transform md:translate-x-0"
              + (isOpenMobile ? ' transform translate-x-0' : ' transform translate-x-[320px]')
            }
          >
            {
              isMobileAndTabletControlVertical
                ? <button
                  className="absolute left-[-34px] top-10 w-[34px] h-[34px] bg-white border border-theme-border flex items-center justify-center"
                  onClick={() => setIsMobileOpen(!isOpenMobile)}
                >
                  <i
                    className={
                      (isOpenMobile ? '' : '  transform rotate-180')
                    }
                  >
                    <IconDoubleCaret />
                  </i>
                </button>
                : <></>
            }
            <div className="mt-4 ml-[29px]">
              <p className="text-[25px] md:text-[30px] font-flama-condensed leading-none">
                Walter<span style={{fontFamily: 'Arial'}}>'</span>s
              </p>
              <p className="flex justify-between items-baseline text-[36px] md:text-[42px] font-flama-condensed">
                <span>Fitting Room</span>
                <span className="text-[14px] md:text-[18px] text-theme-gray font-normal mr-[29px]">
                  { allItemsLength } items
                </span>
              </p>
            </div>

            <ul className="flex w-full mt-5 pb-[5px] border-b border-theme-boder">
              <li
                className={
                  "relative min-w-[100px] py-1 text-center text-[20px] font-bold font-flama-condensed cursor-pointer" +
                  " before:absolute before:bottom-[-5px] before:left-0 before:w-full before:h-[2px] before:bg-theme-primary" +
                  (selectedTab === 'top' ? ' text-theme-primary before:opacity-100' : ' text-theme-black hover:text-black/50 before:opacity-0')
                }
                onClick={() => setSelectedTab('top')}
              >
                Top
              </li>
              <li
                className={
                  "relative min-w-[100px] py-1 text-center text-[20px] font-bold font-flama-condensed cursor-pointer" +
                  " before:absolute before:bottom-[-5px] before:left-0 before:w-full before:h-[2px] before:bg-theme-primary" +
                  (selectedTab === 'bottom' ? ' text-theme-primary before:opacity-100' : ' text-theme-black hover:text-black/50 before:opacity-0')
                }
                onClick={() => setSelectedTab('bottom')}
              >
                Bottom
              </li>
              <li
                className={
                  "relative min-w-[100px] py-1 text-center text-[20px] font-bold font-flama-condensed cursor-pointer" +
                  " before:absolute before:bottom-[-5px] before:left-0 before:w-full before:h-[2px] before:bg-theme-primary" +
                  (selectedTab === 'jacket' ? ' text-theme-primary before:opacity-100' : ' text-theme-black hover:text-black/50 before:opacity-0')
                }
                onClick={() => setSelectedTab('jacket')}
              >
                Jacket
              </li>
            </ul>

            <Scrollbars
              className="w-full"
              style={{height: 'calc(var(--vh) - 235px)'}}
            >
              <div className="pl-[29px] pt-2">
                <ClothTabOption
                  tab={selectedTab}
                  selectedTop={selectTop}
                  selectedBottom={selectBottom}
                  selectedJacket={selectJacket}
                  isSelectJacket={isSelectJacket}
                  onSelectTop={onSelectTop}
                  onSelectBottom={onSelectBottom}
                  onSelectJacket={onSelectJacket}
                  removeJacket={removeJacket}
                />
              </div>
            </Scrollbars>
          </div>
        </div>
      </div>
    </>
  )
}
