import React from 'react';

export const IconAddBag =() => {
	return<svg id="Component_107_2" data-name="Component 107 – 2" xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24">
  <path id="Path_7765" data-name="Path 7765" d="M0,0H24V24H0Z" fill="none"/>
  <path id="Path_7766" data-name="Path 7766" d="M6.331,8H17.67a2,2,0,0,1,1.977,2.3l-1.255,8.152A3,3,0,0,1,15.426,21H8.574a3,3,0,0,1-2.965-2.544L4.354,10.3A2,2,0,0,1,6.331,8Z" fill="none" stroke="#000000" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2"/>
  <path id="Path_7767" data-name="Path 7767" d="M9,11V6a3,3,0,1,1,6,0v5" fill="none" stroke="#000000" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2"/>
  <line id="Line_58" data-name="Line 58" x2="4" transform="translate(19 3)" fill="none" stroke="#000000" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2"/>
  <line id="Line_59" data-name="Line 59" y2="4" transform="translate(21 1)" fill="none" stroke="#000000" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2"/>
</svg>;
}

