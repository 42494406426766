import React, { useEffect, useRef, Suspense, useState } from 'react'
import { Canvas, applyProps } from '@react-three/fiber'
import './index.css'
import { PerspectiveCamera, useGLTF, Html, useProgress, Environment, useHelper  } from '@react-three/drei'
import { Perf } from 'r3f-perf';
import * as THREE from 'three'
import { debounce } from 'lodash'
import { useControls } from 'leva';
import Popup from 'reactjs-popup';

import avatarFile from './assets/sandbox/avatar/Men_Thomas_V1_001.glb'

import { CameraControlSandbox } from './CameraControlSandbox';

const topOptions = [
  {
    file: require('./assets/sandbox/shirt/dustin_rose_001.glb')
  },
  {
    file: require('./assets/sandbox/shirt/hunt_ba_001.glb')
  },
  {
    file: require('./assets/sandbox/shirt/kili_dry_001.glb')
  },
  {
    file: require('./assets/sandbox/shirt/leander_001.glb')
  },
  {
    file: require('./assets/sandbox/shirt/levin_001.glb')
  }
]

const bottomOptions = [
  {
    file: require('./assets/sandbox/trousers/chasy_001.glb')
  },
  {
    file: require('./assets/sandbox/trousers/puy_rose_001.glb')
  },
  {
    file: require('./assets/sandbox/trousers/teba_001.glb')
  }
]

function SlicingPlane({ constant, transparent, rotateX }) {
  return (
    <mesh position={[0, constant, 0]} rotation={[(Math.PI / 2) * rotateX, 0, 0]}>
      <planeBufferGeometry attach="geometry" args={[2, 2]} />
      <meshStandardMaterial
        attach="material"
        roughness={1}
        metalness={0}
        side={THREE.DoubleSide}
        color={transparent ? '#f5f5f5' : '#212121'}
        opacity={transparent ? 0.5 : 1}
        transparent={transparent}
      />
    </mesh>
  )
}

function Avatar({ constant, modelFile }) {
  const { scene: avatarScene, nodes: avatarNodes } = useGLTF(modelFile)
  useEffect(() => {
    applyProps(avatarNodes["Plane"], { visible: false })
    avatarScene.scale.setScalar(1.5)
    avatarScene.traverse(function(obj) {
      if (obj.isMesh) {
        obj.material.roughness = 0.8
        obj.castShadow = true
      }
    })

  }, [avatarScene])

  return (
    <primitive object={avatarScene} />
  )
}

function Bottom({ constant, modelFile }) {
  const { scene: trouScene, nodes: trouNodes } = useGLTF(modelFile)

  useEffect(() => {
    trouScene.scale.setScalar(1.5)
    trouScene.traverse(function(obj) {
      if (obj.isMesh) {
        obj.material.roughness = 0.8
        obj.castShadow = true
      }
    })
  }, [trouScene])

  return (
    <primitive object={trouScene} />
  )
}

function Top({ constant, modelFile }) {
  const { scene: shirtScene, nodes: shirtNodes } = useGLTF(modelFile)

  useEffect(() => {
    shirtScene.scale.setScalar(1.5)
    shirtScene.traverse(function(obj) {
      if (obj.isMesh) {
        obj.material.roughness = 0.8
        obj.castShadow = true
      }
    })
  }, [shirtScene])


  return (
    <primitive object={shirtScene} />
  )
}

function HdriLightOne() {
  return (
    <>
      <mesh rotation-x={Math.PI * -0.5} position={[0, -1.30, 0]} receiveShadow={true}>
        <planeBufferGeometry args={[70, 70]} />
        <meshStandardMaterial color={'#dedede'} metalness={0} roughness={0.89} />
      </mesh>
      <color attach="background" args={['#ffffff']} />
      <fog attach="fog" args={['#ffffff', 6, 14]} />
    </>
  );
}
const HDRI_LIST = {
  'Bright1': 0,
}

function HemisphereLightSandbox({ lightOption, onDelete }) {
  const lightRef = useRef()
  const values = useControls(lightOption.light + ' ' + lightOption.color, {
    color: '#fff',
    groundColor: '#fff',
    position: {
      x : 0,
      y : 1,
      z : 0
    },
    intensity: 0.0,
    visible: true,
  });
  useEffect(() => {
    if (values.visible === false) {
      onDelete(lightOption);
    }
  }, [values.visible])
  return (
    values.visible && <hemisphereLight
      ref={lightRef}
      intensity={values.intensity}
      position={[values.position.x, values.position.y, values.position.z]}
    />
  )
}
function DirectionalLightSandbox({ lightOption, onDelete }) {
  const lightRef = useRef()
  const values = useControls(lightOption.light + ' ' + lightOption.color, {
    color: '#fff',
    groundColor: '#fff',
    position: {
      x : 0,
      y : 1,
      z : 0
    },
    intensity: 0.0,
    visible: true,
  });
  useEffect(() => {
    if (values.visible === false) {
      onDelete(lightOption);
    }
  }, [values.visible])
  useHelper(lightRef, lightOption.helper, 5, lightOption.color)
  return (
    values.visible && <directionalLight
      ref={lightRef}
      intensity={values.intensity}
      position={[values.position.x, values.position.y, values.position.z]}
    />
  )
}
function SpotlLightSandbox({ lightOption, onDelete }) {
  const lightRef = useRef()
  const values = useControls(lightOption.light + ' ' + lightOption.color, {
    color: '#fff',
    groundColor: '#fff',
    position: {
      x : 0,
      y : 1,
      z : 0
    },
    intensity: 0.0,
    angle: 0.3,
    decay: 0.0,
    distance: 0.0,
    penumbra: 0.0,
    power : 0.0,
    castShadow: false,
    visible: true,
  });
  useEffect(() => {
    if (values.visible === false) {
      onDelete(lightOption);
    }
  }, [values.visible])
  useHelper(lightRef, lightOption.helper, lightOption.color)
  return (
    values.visible ? <spotLight
      ref={lightRef}
      color={values.color}
      castShadow={values.castShadow}
      intensity={values.intensity}
      angle={values.angle}
      decay={values.decay}
      distance={values.distance}
      penumbra={values.penumbra}
      power={values.power}
      position={[values.position.x, values.position.y, values.position.z]}
    /> : <></>
  )
}

function LightsSandbox({ lightOption, onDelete }) {
  function handleOnDelete(e) {
    onDelete(e);
  }

  if (lightOption.light === 'hemi') {
    return (
      <HemisphereLightSandbox key='hemisphereLight' onDelete={handleOnDelete} lightOption={lightOption} />
    )
  } else if (lightOption.light === 'spot') {
    return (
      <SpotlLightSandbox key='spotLight' onDelete={handleOnDelete} lightOption={lightOption} />
    )
  } else if (lightOption.light === 'direct') {
    return (
      <DirectionalLightSandbox key='directLight' onDelete={handleOnDelete} lightOption={lightOption} />
    )
  }
}
function LightsSandboxNoHelper({ lightOption, onDelete }) {
  const values = useControls(lightOption.light + ' ' + lightOption.color, {
    visible: true,
    color: '#fff',
    intensity: 0.0,
  });
  useEffect(() => {
    if (values.visible === false) {
      onDelete(lightOption);
    }
  }, [values.visible])

  if (lightOption.light === 'ambient') {
    return (
      values.visible && <ambientLight intensity={values.intensity} color={values.color} />
    )
  }
}

function UploadHdri({ hdri }) {
  return (
    <Environment
      background={false} // Whether to affect scene.background
      files={hdri}
    />
  )
}

export default function App() {
  const cameraControlsRef = useRef(null);
  const camRef = useRef(null);
  const canvasRef = useRef(null);
  const [isResetting, setIsResetting] = useState(false);
  const [selectTop, setSelectTop] = useState(topOptions[0].file);
  const [selectBottom, setSelectBottom] = useState(bottomOptions[0].file);
  const [hdri, setSelectHdri] = useState(HDRI_LIST['Bright1']);
  const [scaling, setScaling] = useState(false);
  const [lights, setLights] = useState([]);
  const [lightForm, setAddLightForm] = useState({
    light: '',
    color: '',
  });

  const [uploadedHDR, setUploadedHDR] = useState(null);
  const [hdriFileName, setHdriFileName] = useState('');

  useEffect(() => {
    function handleWindowResize() {
      const vh = window.innerHeight;
      // Then we set the value in the --vh custom property to the root of the document
      document.documentElement.style.setProperty('--vh', `${vh}px`);
    }
    handleWindowResize();
    window.addEventListener('resize', handleWindowResize);

    return () => {
      window.removeEventListener('resize', handleWindowResize);
    };
  }, []);

  function onSelectTop(e) {
    setSelectTop(e)
  }
  function onSelectBottom(e) {
    setSelectBottom(e)
  }
  function onCanvasCreated(state) {
    state.gl.localClippingEnabled = true;
    if (canvasRef.current) {
      canvasRef.current.addEventListener('wheel', checkScrollDirection);
      canvasRef.current.addEventListener('ontouchstart ', checkMobilePinchStart);
      canvasRef.current.addEventListener('ontouchmove  ', checkMobilePinchMove);
      canvasRef.current.addEventListener('ontouchend  ', checkMobilePinchEnd);
    }
  }

  const checkMobilePinchStart = function(event) {
    // pinchStart
    if (event.touches.length === 2) {
      setScaling(true)
    }
  }

  const checkMobilePinchEnd = function(event) {
    // pinchEnd
    if (scaling) {
      setScaling(false)
    }
  }

  const checkMobilePinchMove = debounce(function(event) {
    // pinchMove
    if (scaling) {
      if (Math.abs(cameraControlsRef.current.distance) > 2.5 && Math.abs(cameraControlsRef.current.distance) < 5 && !isResetting) {
        cameraControlsRef.current.dolly(-4, true)
        setIsResetting(true)
        setTimeout(() => {
          cameraControlsRef.current.setTarget(0, 0, 0, true)
          setIsResetting(false)
        }, 1000)
      } else {
        cameraControlsRef.current.setTarget(0, 0, 0, true)
      }
    }
  }, 400)

  function resetCamera() {
    cameraControlsRef.current.reset(true)
  }
  function handleFormLightChange(e) {
    let helperResult = THREE.HemisphereLightHelper;
    const lightValue = e.target.value;
    if (lightValue === 'hemi') {
      helperResult = THREE.HemisphereLightHelper;
    } else if (lightValue === 'spot') {
      helperResult = THREE.SpotLightHelper;
    } else if (lightValue === 'direct') {
      helperResult = THREE.DirectionalLightHelper;
    }
    setAddLightForm({
      ...lightForm,
      light: lightValue,
      helper: helperResult
    });
  }
  function handleFormColorChange(e) {
    setAddLightForm({
      ...lightForm,
      color: e.target.value
    });
  }
  function submitLightForm(closeFn) {
    if (lightForm.light === '' || lightForm.color === '') {
      return;
    }
    setLights([
      ...lights,
      lightForm
    ]);
    setTimeout(() => {
      setAddLightForm({
        color: '',
        light: ''
      });
      closeFn()
    }, 200);
  }
  function submitHdr(closeFn) {
    if (!uploadedHDR) {
      return;
    }
    setTimeout(() => {
      closeFn()
    }, 200);
  }
  function onDeleteHdr() {
    setUploadedHDR(null);
    setHdriFileName('')
  }
  function onDeleteLight(e) {
    const removeds = lights.filter((item) => item.light !== e.light && item.color !== e.color)
    setLights([
      ...removeds,
    ]);
  }

  function onFileHdriChange(e) {
    const file = e.target.files[0];
    setHdriFileName(file.name)
    setUploadedHDR(URL.createObjectURL(file))
  }

  const checkScrollDirection = debounce(function(event) {
    if (!checkScrollDirectionIsUp(event)) {
      if (Math.abs(cameraControlsRef.current.distance) > 2.5 && Math.abs(cameraControlsRef.current.distance) < 5 && !isResetting) {
        cameraControlsRef.current.dolly(-4, true)
        setIsResetting(true)
        setTimeout(() => {
          cameraControlsRef.current.setTarget(0, 0, 0, true)
          setIsResetting(false)
        }, 1000)
      } else {
        cameraControlsRef.current.setTarget(0, 0, 0, true)
      }
    }
  }, 400);

  function checkScrollDirectionIsUp(event) {
    if (event.wheelDelta) {
      return event.wheelDelta > 0;
    }
    return event.deltaY < 0;
  }

  function Loader() {
    const { progress } = useProgress()
    return <Html center>{progress} % loaded</Html>
  }

  const contentStyle = {
    background: '#fff',
    padding: '20px',
    width: '80%',
    color: '#000',
    border: '2px solid',
  };
  return (
    <>
      <div ref={canvasRef} className="relative w-full h-full" style={{height: 'var(--vh)'}}>
        <Canvas
          className="absolute top-0 left-0 w-full h-full"
          onCreated={onCanvasCreated}
          shadows
        >
          <PerspectiveCamera
            ref={camRef}
            fov={30}
            near={0.1}
            far={100}
            makeDefault
            position={[0, 0.786969454578296, 6.955634977391649]}
          />
          <Suspense fallback={<Loader />}>
            <group position={[0, -1.3, 0]}>
              <Avatar modelFile={avatarFile} />
              <Bottom modelFile={selectBottom} />
              <Top modelFile={selectTop} />
            </group>
          </Suspense>
          <CameraControlSandbox
            ref={cameraControlsRef}
            camRef={camRef}
          />
          <HdriLightOne />
          {
            uploadedHDR ? <UploadHdri hdri={uploadedHDR} /> : <></>
          }
          {
            lights.map((light, index) => {
              if (light.light === 'ambient') {
                return <LightsSandboxNoHelper key={'light' + index} lightOption={light} onDelete={onDeleteLight} />
              } else {
                return <LightsSandbox key={'light' + index} lightOption={light} onDelete={onDeleteLight} />
              }
            })
          }
          {/* <Perf position="top-left" /> */}
        </Canvas>
        <ul className="absolute left-1/2 md:left-4 bottom-[80px] transfrom -translate-x-1/2 md:translate-x-0 flex gap-x-4 z-10">
          {
            topOptions.map((item, index) => <li key={'top'+index}>
              <button
                className={
                  "w-full h-full border-2 rounded-md text-sm font-bold p-4"
                  + (item.file === selectTop ? ' border-black bg-white' : ' border-white')
                }
                onClick={() => onSelectTop(item.file)}
              >
                Top {index + 1} { hdri }
              </button>
            </li>)
          }
        </ul>
        <ul className="absolute left-1/2  md:left-4 bottom-4 transfrom -translate-x-1/2 md:translate-x-0 flex gap-x-4 z-10">
          {
            bottomOptions.map((item, index) => <li key={'bottom'+index}>
              <button
                className={
                  "w-full h-full border-2 rounded-md text-sm font-bold p-4"
                  + (item.file === selectBottom ? ' border-black bg-white' : ' border-white')
                }
                onClick={() => onSelectBottom(item.file)}
              >
                Bottom {index + 1}
              </button>
            </li>)
          }
        </ul>
        <ul className="absolute right-4 bottom-4 transfrom -translate-x-1/2 md:translate-x-0 flex flex-col gap-4 z-10">
          <li>
            {
              uploadedHDR
                ? <button
                className={
                  "w-full h-full border-2 rounded-md text-sm font-bold p-4 border-black bg-white"
                }
                onClick={onDeleteHdr}
              >
                Delete HDR { hdriFileName }
              </button>
                : <Popup
                trigger={
                  <button
                    className={
                      "w-full h-full border-2 rounded-md text-sm font-bold p-4 border-black bg-white"
                    }
                  >
                    Add HDR
                  </button>
                }
                contentStyle={contentStyle}
                modal
              >
                {close => (
                <div className="flex flex-col">
                 <label className="custom-file-upload">
                    <input type="file" onChange={onFileHdriChange} />
                    HDRI
                  </label>
                  <p>{ hdriFileName }</p>
                  <button
                    className="border font-bold mt-4 bg-slate-900 text-white"
                    onClick={() => submitHdr(close)}
                  >
                    Submit
                  </button>
                </div>
                )}
              </Popup>
            }
          </li>
          <li>
            <Popup
              trigger={
                <button
                  className={
                    "w-full h-full border-2 rounded-md text-sm font-bold p-4 border-black bg-white"
                  }
                >
                  Add Light
                </button>
              }
              contentStyle={contentStyle}
              modal
            >
              {close => (
              <div className="flex flex-col">
                <select className="border" onChange={e => handleFormLightChange(e)}>
                  <option value="none">--------</option>
                  <option value="hemi">hemisphereLight</option>
                  <option value="ambient">ambientLight</option>
                  <option value="spot">spotLight</option>
                  <option value="direct">directionalLight</option>
                </select>
                <p className="mt-2">
                  Selected: { lightForm.light }
                </p>
                <div className="mt-2">
                  <label className="mr-2">Color:</label>
                  <input type="color" id="favcolor" name="favcolor" value="#ff0000" onChange={e => handleFormColorChange(e)} />
                </div>
                <p className="mt-2">
                  Selected: { lightForm.color } <span className="inline-block min-w-4 w-4 h-4 rounded-full ml-2" style={{ backgroundColor: lightForm.color }}></span>
                </p>
                <button
                  className="border font-bold mt-4 bg-slate-900 text-white"
                  onClick={() => submitLightForm(close)}
                >
                  Submit
                </button>
              </div>
              )}
            </Popup>
            {/* <button
              className={
                "w-full h-full border-2 rounded-md text-sm font-bold p-4 border-black bg-white"
              }
              onClick={onClickAddLight}
            >
              Add light
            </button> */}
          </li>
          <li>
            <button
              className={
                "w-full h-full border-2 rounded-md text-sm font-bold p-4 border-black bg-white"
              }
              onClick={resetCamera}
            >
              Reset Camera
            </button>
          </li>
        </ul>
      </div>
    </>
  )
}
