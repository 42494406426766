
import React from 'react';

export const IconDoubleCaret = () => {
	return <svg height="20" version="1.1" viewBox="0 0 700 600" xmlns="http://www.w3.org/2000/svg" fill="#000000">
  <g>
   <path d="m357.39 121.97c22.734 19.152 45.359 38.191 68.098 57.344 36.176 30.465 72.465 60.93 108.64 91.391 8.3984 7.0547 16.688 14.113 25.09 21.055v-23.742c-22.734 19.152-45.359 38.191-68.098 57.344-36.176 30.465-72.465 60.93-108.64 91.391-8.3984 7.0547-16.688 14.113-25.09 21.055-6.9453 5.8242-6.0469 17.695 0 23.742 6.832 6.832 16.801 5.8242 23.742 0 22.734-19.152 45.359-38.191 68.098-57.344 36.176-30.465 72.465-60.93 108.64-91.391 8.3984-7.0547 16.688-14.113 25.09-21.055 6.4961-5.4883 6.4961-18.258 0-23.742-22.734-19.152-45.359-38.191-68.098-57.344-36.176-30.465-72.465-60.93-108.64-91.391-8.3984-7.0547-16.688-14.113-25.09-21.055-6.9453-5.8242-16.91-6.832-23.742 0-6.0469 6.0469-6.9414 17.918 0 23.742z"/>
   <path d="m117.04 121.97c22.734 19.152 45.359 38.191 68.098 57.344 36.176 30.465 72.465 60.93 108.64 91.391 8.3984 7.0547 16.688 14.113 25.09 21.055v-23.742c-22.734 19.152-45.359 38.191-68.098 57.344-36.176 30.465-72.465 60.93-108.64 91.391-8.3984 7.0547-16.688 14.113-25.09 21.055-6.9453 5.8242-6.0469 17.695 0 23.742 6.832 6.832 16.801 5.8242 23.742 0 22.734-19.152 45.359-38.191 68.098-57.344 36.176-30.465 72.465-60.93 108.64-91.391 8.3984-7.0547 16.688-14.113 25.09-21.055 6.4961-5.4883 6.4961-18.258 0-23.742-22.734-19.152-45.359-38.191-68.098-57.344-36.176-30.465-72.465-60.93-108.64-91.391-8.3984-7.0547-16.688-14.113-25.09-21.055-6.9414-5.8281-17.02-6.7227-23.742-0.003906-6.0469 6.0508-7.0547 17.922 0 23.746z"/>
  </g>
 </svg>;
}